import { useState } from 'react';

const useDatePickerInstruction = () => {
  const [instruction, setInstruction] = useState('Use arrow keys to navigate dates.');

  const handleKeyDown = event => {
    switch (event.key) {
      case 'ArrowLeft':
        setInstruction('Moving focus to the previous day.');
        break;
      case 'ArrowRight':
        setInstruction('Moving focus to the next day.');
        break;
      case 'ArrowUp':
        setInstruction('Moving focus to the same day of the previous week.');
        break;
      case 'ArrowDown':
        setInstruction('Moving focus to the same day of the following week.');
        break;
      case 'PageUp':
        if (e.shiftKey) {
          setInstruction('Moving focus to the same date of the previous year.');
        } else {
          setInstruction('Moving focus to the same date of the previous month.');
        }
        break;
      case 'PageDown':
        if (e.shiftKey) {
          setInstruction('Moving focus to the same date of the following year.');
        } else {
          setInstruction('Moving focus to the same date of the following month.');
        }
        break;
      case 'Home':
        setInstruction('Moving to the first day of the month.');
        break;
      case 'End':
        setInstruction('Moving to the last day of the month.');
        break;
      case 'Enter':
      case ' ':
        setInstruction('Filling the date textbox with the selected date.');
        break;
      default:
        break;
    }
  };

  return { datePickerInstruction: instruction, handleDatePickerKeyDown: handleKeyDown };
};

export default useDatePickerInstruction;
